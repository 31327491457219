import React from "react";
import bgImg from "../../static/images/private-events-hero-img.png";
import Button from "../Button";
const SocialSaturdaysHero = () => {
  return (
    <>
      <div
        className="w-full flex items-center bg-cover bg-no-repeat mt-10 h-[504px] mb-[104px] lg:mt-20 justify-center"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <h1 className="font-bold fontFamily2 text-white text-[42px] leading-[51px] text-center">
          Day Pass
        </h1>
      </div>
      <div className="w-[90%] md:w-[581px] mx-auto mb-[83px]">
        <h2 className="text-4xl leading-[44px] fontFamily2 font-bold text-gray_900 mb-4">
          Social Saturdays
        </h2>
        <p className="text-typography_body text-sm  leading-[18px] font-semibold mb-4 fontFamily2">
          ( Available Saturdays only )
        </p>
        <p className="fontFamily1 text-typography_body leading-7 text-base font-normal mb-5">
        Experience ultimate relaxation and recovery with HighKey Balance's
        Day Pass for $80 (normally $160)! Enjoy Cryotherapy, Infrared Sauna,
        and Compression Therapy all in one visit. Elevate your wellness
        routine today! 
        </p>
        <a href="https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/services?lid=429303&eid=1544098&oiid=p%3A432967&pId=400656">
            <Button
            texts={"Book Now"}
            customClass={
                "uppercase text-sm font-semibold text-white bg-primary_green fontFamily2 tracking-[0.03em]  w-[8rem] rounded-sm h-10 transition hover:bg-green_400 hover:text-purple mt-4"
            }
            />
        </a>
      </div>
    </>
  );
};

export default SocialSaturdaysHero;
