import React from "react";
import Layout from "../components/Layout";
import SocialSaturdaysHero from "../components/SocialSaturdaysHero";
import Seo from "../components/SEO";

const META_DATA = {
  title: "Day Pass | Washington DC | HighKey Balance",
  description: `HighKey Balance wellness experts specialize in personalized wellness and recovery treatment for Washington, D.C., athletes, and others who train and play hard.`,
};

const SocialSaturdays = () => {
  return (
    <Layout>
      <Seo {...META_DATA} />
      <SocialSaturdaysHero />
    </Layout>
  );
};

export default SocialSaturdays;
